



















import Vue from 'vue';
import { mapState } from 'vuex';
import { deployedContracts } from '../../deployed-contracts';

export default Vue.extend({
  name: 'Header',
  methods: {
    async mint() {
      if (!this.$refs.balanceMint.value) {
        return;
      }
      this.$toasted.show('Processing...');
      const {originalTxId: txId} = await this.contract.writeInteraction({
        function: 'mint',
        qty: parseInt(this.$refs.balanceMint.value),
      });

      const newResult = await this.contract.currentState();
      if (newResult) {
        this.$toasted.clear();
        this.$toasted.global.success('Processed!');
        this.$toasted.global.close(
          `<div>Interaction id: <a href="https://sonar.warp.cc/#/app/interaction/${txId}" target="_blank">${txId}</a></div>`
        );
      }
      this.$parent.updateBalances(newResult);
      this.$refs.balanceMint = '';
    },
  },
  computed: {
    contractAddress() {
      return deployedContracts.fc;
    },
    ...mapState(['contract', 'arweave', 'walletAddress']),
  },
});
